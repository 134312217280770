import React, { useEffect } from "react";
import { Navbar, Footer } from "../../Components";

import "./TermsUse.css";

const items = [
  {
    title: "Introduction",
    description: ["By using the Tiger Byte Consulting (TBC) website, you agree to the following terms and conditions (the \"Terms\"). If you do not agree with these terms, please refrain from using our website."],
  },
  {
    title: "Use of the Website",
    description: [
      "2.1. Users are permitted to use our website for personal and business purposes. Any unauthorized use or duplication of our content without express permission is prohibited.",
      "2.2. Users must not use our website in any way that may be harmful, unlawful, or otherwise inappropriate."
    ],
  },
  {
    title: "Intellectual Property",
    description: ["All content on the TBC website, including text, graphics, logos, and images, is the property of TBC or its content suppliers and is protected by international copyright laws."],
  },
  {
    title: "Limitation of Liability",
    description: [
      "4.1. While we strive to keep the information on our website accurate and up-to-date, TBC will not be liable for any inaccuracies, errors, or omissions found therein.",
      "4.2. TBC is not liable for any direct or indirect damages arising from the use or inability to use our website."
    ],
  },
  {
    title: "External Links",
    description: ["Our website may contain links to external websites. TBC is not responsible for the content, privacy policies, or practices of any third-party sites."],
  },
  {
    title: "User-Generated Content",
    description: [
      "6.1. If our website allows comments or other user-generated content, TBC is not responsible for opinions or messages posted by users.",
      "6.2. TBC reserves the right to remove any user-generated content deemed inappropriate or harmful without notification."
    ],
  },
  {
    title: "Changes to These Terms",
    description: ["TBC may update these Terms from time to time. Any changes will be immediately posted on our website. It is the user's responsibility to check for updates regularly."],
  },
  {
    title: "Governing Law",
    description: ["These Terms are governed by and interpreted following the laws of [Your Country/State], and you submit to the non-exclusive jurisdiction of the state and federal courts located in [Your Country/State] for the resolution of any disputes."],
  },
  {
    title: "Contact Us",
    description: [
      "For any questions regarding these Terms & Conditions, please contact:",
      "Email: info@tigerbyte.ai"
    ],
  }
]

function TermsUse() {


  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
    console.log(window.location)
  }, [window.location.pathname]);

  return (
    <>
      <Navbar />
      <div className="terms-of-use">
        <h1>Privacy Policy</h1>
        <div className="terms-of-use-content">
          {
            items.map((val, i) => {
              return (
                <div key={i} >
                  <h2 className="terms-of-use-item-title">{`${i + 1}. ${val.title}`}</h2>
                  <p className="terms-of-use-item-description">
                    {
                      val.description.map((val) => (
                        <>
                          <p>{val}</p>

                          {/* <br /> */}
                        </>
                      ))
                    }
                  </p>
                </div>
              )
            })
          }
        </div>
      </div>
      <Footer />
    </>
  )
}

export { TermsUse };