import React, { useEffect } from "react";
import "./Home.css";
import { Navbar, Footer } from "../../Components";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css"
import { useNavigate } from "react-router-dom";

const tempImgs = [
  {
    image: "/Data_Analysis.jpg",
    title: "Data, Analytics & Intelligence",
    description: "Leverage our analytics and intelligence services to transform data into actionable insights for better decision-making and a competitive advantage.",
  },
  {
    image: "/CFO_Advisory.jpeg",
    title: "CFO Advisory & Diligence",
    description: "Receive expert financial advice and thorough due diligence to strengthen your company’s financial standing and strategic growth.",
  },
  {
    image: "Risk_Management.jpg",
    title: "Risk Management Advisory",
    description: "Safeguard your business by identifying and mitigating risks with our customized risk management solutions to ensure compliance and protect assets.",
  },
  {
    image: "Strategy_Consulting.jpg",
    title: "Strategy Consulting",
    description: "Develop and implement effective strategies for growth, innovation, and competitive advantage with our expert consulting services.",
  }
];

const slideIndicator = (index: number | undefined) => (<div className="indicator"></div>);

function Home() {
  const navigate = useNavigate();


  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
    console.log(window.location)
  }, [window.location.pathname]);

  return (
    <>
      <Navbar />
      <div className="home-content">
        <Slide transitionDuration={400} duration={7000} indicators={slideIndicator} easing="ease" arrows={false} canSwipe={false}>
          {
            tempImgs.map((imageData, i) => (
              <div className="slide-image" key={i}>
                <div style={{ backgroundImage: `url(${imageData.image})` }}>
                  <div className="slide-image-description-container">
                    <h1 className="slide-image-header">
                      {imageData.title}
                    </h1>
                    <p className="slide-image-description">{imageData.description}</p>
                    <button onClick={() => navigate("/services")} className="slide-image-learn-more">Learn More</button>
                  </div>
                </div>
              </div>
            ))
          }
        </Slide>

        <div className="clients-container">
          <h1 className="clients-title">Our Clients</h1>
          <div className="clients">
            <img src="/clients/US_OPM.png" />
            <img src="/clients/US_DOA.png" />
            <img src="/clients/US_DOD.png" />
            <img src="/clients/US_DOL.png" />
            <img src="/clients/US_DOHS.png" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export { Home }