import React, { useEffect } from "react";
import "./Heatmap.css";
import { Navbar, Footer } from "../../Components";

function Heatmap() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
    console.log(window.location)
  }, [window.location.pathname]);

  return (
    <>
      <Navbar />
      <iframe src="https://heatmap.tigerbyte.ai/d/district?logo=none" className="heatmap-iframe">

      </iframe>
      <Footer />
    </>
  )
}

export { Heatmap };
