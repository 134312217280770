import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Squash } from "hamburger-react";
function Navbar() {
  const navigate = useNavigate();

  const location = useLocation();

  const [visible, setVisible] = useState(true);
  const scrollRef = useRef(window.scrollY);

  const { width, height } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);

  const onScroll = () => {
    const scroll = window.scrollY;
    if (scroll > scrollRef.current && scroll > 110) {
      setVisible(false);
      scrollRef.current = scroll;
    } else {
      setVisible(true);
      scrollRef.current = scroll;
    }
  }

  const toggleNav = () => {
    const toSet = !isOpen;
    setIsOpen(toSet);

    const body = document.getElementsByTagName("body")[0];

    if (toSet) {
      body.style.height = "100%";
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "scroll";
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    }
  }, []);

  return (
    <>
      <nav className={`navbar ${visible ? "" : "nav-hidden"}`}>
        {/* <div className="logo" onClick={() => navigate("/")}> */}
        {/* <h2 className="company-name">Tiger Byte Consulting</h2> */}
        <img onClick={() => navigate("/")} src="/TBC_Header.png" alt="Tiger Byte Logo" className="company-logo" />
        {/* </div> */}
        {/* Full size */}
        {
          width > 1100 ?
            <div className="nav-options">
              <Link className={(location.pathname === "/" ? "active-link" : "") + " nav-option"} to={"/"}>HOME</Link>
              <Link className={(location.pathname === "/about-us" ? "active-link" : "") + " nav-option"} to={"/about-us"}>ABOUT US</Link>
              <Link className={(location.pathname === "/services" ? "active-link" : "") + " nav-option"} to={"/services"}>SERVICES</Link>
              <Link className={(location.pathname === "/heatmap" ? "active-link" : "") + " nav-option"} to={"/heatmap"}>HEATMAP</Link>
              <Link className={(location.pathname === "/contact" ? "active-link" : "") + " nav-option"} to={"/contact"}>CONTACT</Link>
            </div>
            : <>
              <Squash color="white" toggle={toggleNav} toggled={isOpen} rounded />
              <div className={`menu-panel ${isOpen ? "open" : "closed"}`}>
                <Link onClick={() => toggleNav()} className={(location.pathname === "/" ? "active-link" : "") + " nav-option"} to={"/"}>HOME</Link>
                <Link onClick={() => toggleNav()} className={(location.pathname === "/about-us" ? "active-link" : "") + " nav-option"} to={"/about-us"}>ABOUT US</Link>
                <Link onClick={() => toggleNav()} className={(location.pathname === "/services" ? "active-link" : "") + " nav-option"} to={"/services"}>SERVICES</Link>
                <Link onClick={() => toggleNav()} className={(location.pathname === "/heatmap" ? "active-link" : "") + " nav-option"} to={"/heatmap"}>HEATMAP</Link>
                <Link onClick={() => toggleNav()} className={(location.pathname === "/contact" ? "active-link" : "") + " nav-option"} to={"/contact"}>CONTACT</Link>
              </div>
            </>
        }
      </nav>
      <div className="navbar-spacer" />
    </>
  )
}

export { Navbar }
