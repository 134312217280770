import React, { useEffect } from "react";
import "./ContactUs.css";
import { ReactComponent as LinkedIn } from "../../svg/linkedin.svg";
import { Footer, Navbar } from "../../Components";

function ContactUs() {


  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
    console.log(window.location)
  }, [window.location.pathname]);

  return (
    <>
      <Navbar />
      <div className="contact-page">
        <img src="/ceo.png" className="contact-ceo" alt="Victoria J. Gaffney" />
        <h1 className="contact-ceo-name">Victoria J. Gaffney</h1>
        <div className="contact-inner">
          <div className="contact-container">
            <h1 className="contact-title">Email Us</h1>
            <a className="contact-mail" href="mailto:Victoria@tigerbyte.ai">Victoria@tigerbyte.ai</a>
          </div>
          <div className="contact-container">
            <h1 className="contact-title">LinkedIn</h1>
            <div>
              <LinkedIn onClick={() => window.open("https://www.linkedin.com/in/vjgaffneyceo/", "_blank")} className="contact-linkedin" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="contact-container">
        <h3 className="contact-header">CONTACT US</h3>
        <form className="contact-form" id="contact-form" name="contact-form">
          <div className="contact-row">
            <input type="text" required className="contact-input" name="name" placeholder="Name" />
            <input pattern="^[+]?([(][0-9]{1,3}[)][-]?)?([0-9][-]?){3,16}[0-9]$" title="XXX-XXX-XXXX" type="tel" required className="contact-input" name="phone" placeholder="Phone Number" />
          </div>
          <div className="contact-row">
            <input type="email" required className="contact-input" name="email" placeholder="Email Address" />
            <input type="text" required className="contact-input" name="subject" placeholder="Email Subject" />
          </div>
          <textarea name="message" id="long-message" className="textarea-message" placeholder="Send us a message"></textarea>
          <input type="submit" className="contact-submit-button" onSubmit={(ev) => { ev.preventDefault() }} value="Submit" />
        </form>
      </div> */}
      <Footer />
    </>
  )
}

export { ContactUs };