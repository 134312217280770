import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, ScrollRestoration } from 'react-router-dom';

import {
  AboutUs,
  Home,
  Heatmap,
  PrivacyPolicy,
  Services,
  TermsUse,
  ContactUs,
  NotFound
} from "./Routes";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/contact",
    element: <ContactUs />
  },
  {
    path: "/heatmap",
    element: <Heatmap />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />
  },
  {
    path: "/terms-of-use",
    element: <TermsUse />
  },
  {
    path: "*",
    element: <NotFound />
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <>
    <RouterProvider router={router} />
    {/* <ScrollRestoration /> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
