import React, { useEffect } from "react";
import "./AboutUs.css";
import { Navbar, Footer } from "../../Components";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function AboutUs() {
  const { width, height } = useWindowDimensions();



  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
    console.log(window.location)
  }, [window.location.pathname]);

  return (
    <>
      <Navbar />


      <div className="who-we-are">
        <div className="who-we-are-description">
          <h3 className="title">WHO WE ARE</h3>
          <h1 className="company-title">Tiger Byte Consulting</h1>
          <p className="body">We aim to empower organizations with innovative, data-driven solutions that drive meaningful impact. As a women-led business, we are committed to fostering a culture of integrity, trust, and curiosity, where every challenge is an opportunity for learning and innovation. Our people are our strength, and we believe in nurturing their growth as we contribute to our client's missions. We strive to bring cutting-edge analytics and strategic insights to our clients, helping them navigate complex landscapes and make informed decisions for a more sustainable future.</p>
        </div>
        {
          width < 1000 ?
            <></> :
            <img className="tigerCity-image" src="/tiger.webp" alt="Tiger in the City" />
        }
      </div>


      <div className="approach" style={{ backgroundImage: "url(/about_us_background2.png)", backgroundSize: "cover" }}>
        <div className="our-approach-c1">
          <p className="approach-title">OUR APPROACH</p>
          <h1 className="approach-large">At TBC, we are committed to providing our clients with the best solutions to their business challenges. Our approach is centered on collaboration, innovation, and knowledge-sharing.</h1>
          <p className="approach-small">We work closely with our clients to understand their needs and develop customized solutions that fit their unique business requirements. Our team brings a wealth of experience and expertise to every engagement, ensuring that our clients receive the highest quality service.</p>
        </div>
        <div className="our-approach-c2">
          <p className="approach-title">CORE VALUES</p>
          <div className="core-values">
            <div className="core-value">
              <h3 className="core-title">Excellence</h3>
              <p className="core-description">We strive for excellence in everything we do. We are committed to delivering high-quality services that exceed our clients' expectations.</p>
            </div>
            <div className="core-value">
              <h3 className="core-title">Integrity</h3>
              <p className="core-description">We operate with the highest level of integrity and ethics. We are honest, transparent, and accountable in all our interactions.</p>
            </div>
            <div className="core-value">
              <h3 className="core-title">Collaboration</h3>
              <p className="core-description">We believe that collaboration is key to success. We work closely with our clients, partners, and colleagues to achieve common goals.</p>
            </div>
            <div className="core-value">
              <h3 className="core-title">Curiosity</h3>
              <p className="core-description">We believe that curiosity drives innovation and growth. We constantly seek to learn, question the status quo, and explore new avenues to deliver exceptional value to our clients and partners.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="leadership">
        <div className="leadership-heading">
          <h1>Meet the Leadership Team</h1>
        </div>
        <div className="c-cuite-leaders">
          <div className="leader">
            <img src="/ceo.png" className="leader-image" />
            <h3 className="leader-name">Victoria J. Gaffney</h3>
            <h4 className="leader-position">Chief Executive Officer</h4>
            <p className="leader-statement">Victoria is an accomplished management consulting and corporate finance executive with over 20 years of rich industry experience. Victoria's dynamic, hands-on leadership style, combined with her keen focus on data-driven solutions, underpins the company's mission to empower Federal agencies with improved decision-making capabilities. She has served as a trusted advisor to executive leadership across various organizations, contributing to complex strategic and operational transformation initiatives to enhance revenue and profitability, reduce costs, and improve efficiency. As a recognized expert in Federal Cost Accounting, she has led large-scale transformations at organizations like the USDA, Treasury, Commerce, Navy, Army, and OPM.

              She holds a Bachelor's degree in Government from the College of William & Mary and is a Certified Project Management Professional (PMP). Victoria brings extensive knowledge of federal procurement, FAR, DCAA, and Corporate Finance and expertise in various technologies, including Oracle, SAP HANA, AWS, Azure, and Google Cloud.</p>
          </div>

          <div className="leader">
            <img src="/Charity.jpg" className="leader-image" />
            <h3 className="leader-name"> Charity M. Stoaks</h3>
            <h4 className="leader-position">Partner</h4>
            <p className="leader-statement">Charity excels in managing large-scale transformation projects across diverse international settings in both the private and public sectors. Her key achievements include implementing ISO 9001 across 16 locations in 5 countries, with certification achieved within 24 months, and developing a standardized quality management framework. She has enhanced performance and stakeholder satisfaction through standardized protocols and a tailored quality management model for regulatory agencies. Charity’s expertise includes gap analysis, project planning, quality management systems, and continuous improvement.



              Her clients value her ability to manage complex projects and deliver tangible results. Additionally, Charity’s diverse background includes entrepreneurship, historical preservation, and expertise in poly farming, viticulture, and winemaking, reflecting her broad skill set and passion for integrating historical authenticity with contemporary design.</p>
          </div>

          <div className="leader">
            <img src="/cto.png" className="leader-image" />
            <h3 className="leader-name">Janaki Parameswaran</h3>
            <h4 className="leader-position">Chief Technology Officer</h4>
            <p className="leader-statement">Janaki is known for her expertise in technology and FinTech, which includes product management, software development, analytics, and cloud strategy, with a particular focus on AWS, microservices, application security, and agile methodologies. With years of experience at top financial institutions like Capital One and FINRA, she has a proven track record of spearheading complex enterprise systems, guiding cloud migrations, and implementing business intelligence solutions and big data analytics. Janaki's ability to bridge the gap between IT and business has consistently made her a valuable asset in every professional endeavor.

              With an academic foundation in Applied Math from the esteemed Indian Institute of Technology, Madras, and an advanced degree in Operations Research from the University of Delaware, Janaki brings a blend of rigorous analytical thinking and practical application to the team.</p>
          </div>
          <div className="leader">
            <img src="/cdo.png" className="leader-image" />
            <h3 className="leader-name">Vijay Perincherry, PhD</h3>
            <h4 className="leader-position">Chief Data Scientist</h4>
            <p className="leader-statement">Vijay is an Applied Mathematician who specializes in behavioral and cognitive analyses, and their applications to the design of learning systems. Over the last 15 years, he has applied data analytics to a wide array of fields ranging from investment analysis to educational effectiveness.



              Vijay is an accomplished innovator with over 20 patents to his name. His pioneering work includes a patented method for developing adaptive algorithms that significantly enhance processing efficiency and another for profiling consumer attitudes based on behavioral data. He has an Engineering Degree from the Indian Institute of Technology, Chennai, India and a Ph.D. in Applied Sciences from the University of Delaware.</p>
          </div>
        </div>

      </div>
      <Footer />
    </>
  )
}

export { AboutUs };
