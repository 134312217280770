import React from "react";
import "./Footer.css";

import { ReactComponent as LinkedInLogo } from "../../svg/linkedin.svg";

import { Link } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function Footer() {

  const { width } = useWindowDimensions();

  return (
    <footer className="footer">



      <div className="social">
        <div className="socials">
          <p className="social-title">Socials</p>
          <div className="icon-container">
            <LinkedInLogo className="linkedin" onClick={() => window.open("https://www.linkedin.com/in/vjgaffneyceo/", "_blank")} />
          </div>
        </div>
        <div className="email">
          <p className="social-title">Email</p>
          <a className="social-text" href="mailto:Victoria@tigerbyte.ai">Victoria@tigerbyte.ai</a>
        </div>
        <div className="offices">
          <p className="social-title">Offices</p>
          <p className="social-text">Arlington, VA</p>
        </div>


      </div>

      <div className="cp-container">
        <div className="privacy-terms">
          <Link className="privacy-terms-link" to="/privacy-policy">Privacy Policy</Link>
          <Link className="privacy-terms-link" to="/terms-of-use">Terms of Use</Link>
          <Link className="privacy-terms-link" to="/contact">Contact Us</Link>
        </div>
        <p>© Copyright Tiger Byte Consulting, LLC. 2024. All rights reserved.</p>
      </div>
      {
        width < 850 ?
          <img className="wosbcert-mobile" src="/WOSB_logo_mobile.webp" alt="Woman Owned Small Business Certified" onClick={() => window.open("https://www.sba.gov/federal-contracting/contracting-assistance-programs/women-owned-small-business-federal-contract-program", "_blank")} />
          :
          < img className="wosbcert" src="/WOSBCertified.png" alt="Woman Owned Small Business Certified" onClick={() => window.open("https://www.sba.gov/federal-contracting/contracting-assistance-programs/women-owned-small-business-federal-contract-program", "_blank")} />
      }
    </footer>
  )

}

export { Footer }
