import React, { useEffect } from "react";
import "./Services.css";
import { Navbar, Footer } from "../../Components";

import { ReactComponent as DownRightArrow } from "../../svg/downright.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";


const snippetItems = [
  {
    imageUrl: "/laptop.png",
    title: "Data Strategy & Governance",
    snippet: "Ensure data quality, security, and compliance by assisting clients in defining data strategies.",
  },
  {
    imageUrl: "/stickynotes.png",
    title: "Advanced Analytics & Insights",
    snippet: "Leveraging advanced statistical techniques and models to glean deeper insights from data.",
  },
  {
    imageUrl: "/laptop2.png",
    title: "AI-Powered Automation Solutions",
    snippet: "We design and implement solutions that automate repetitive tasks, using machine learning and AI to enhance efficiency, accuracy, and scalability.",
  },
  {
    imageUrl: "/robotarm.png",
    title: "Prototyping",
    snippet: "Developing prototypes to assist clients in quickly evaluating new data, insights, or solutions",
  },
  {
    imageUrl: "/ai.png",
    title: "Generative AI Acceleration",
    snippet: "Enable organizations to pilot and harness emerging technologies' transformative potential rapidly without a large upfront investment or long-term commitment.",
  },
];

function Services() {

  const { width, height } = useWindowDimensions();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
    console.log(window.location)
  }, [window.location.pathname]);

  return (
    <>
      <Navbar />
      <div className="services">
        <div className="main-services">
          <div className="services-header">
            <h1>Empowering our clients to unlock the full potential of their data with cutting-edge AI solutions. </h1>
            {
              width < 675 ?
                <></>
                :
                <DownRightArrow className="down-right" />
            }
          </div>
          {
            width < 1475 ?
              <></>
              :
              <div className="heatmap-container">
                <img src="/Heatmap.png" alt="Heatmap" className="heatmap-image" onClick={() => navigate("/heatmap")} />
                <h3 className="heatmap-subtitle">Italian American Voter Turnout Heatmap</h3>
              </div>
          }
        </div>

        <div className="services-container" style={{ backgroundImage: "url('/congress.webp')" }}>

          <div className="services-col">
            <div className="service-item">
              <div className="service-item-container">
                <p className="service-item-title">Data, Analytics & Intelligence</p>
                <p className="service-item-description">Bridging the gap between raw data and actionable insights, empowering businesses to fully harness their data.</p>
              </div>
            </div>
            <div className="service-item">
              <div className="service-item-container">
                <p className="service-item-title">CFO Advisory & Diligence</p>
                <p className="service-item-description">Financial foresight drives success. We provide CFO advisory and diligence services to ensure your strategies are robust, future-proof, and mission-aligned.</p>
              </div>
            </div>
          </div>


          <div className="services-col">
            <div className="service-item">
              <div className="service-item-container">
                <p className="service-item-title">Risk Management Advisory</p>
                <p className="service-item-description">In an uncertain world, preparation is key. Our services ensure your organization stays resilient, prepared, and ahead of potential threats.</p>
              </div>
            </div>
            <div className="service-item">
              <div className="service-item-container">
                <p className="service-item-title">Strategy Consulting</p>
                <p className="service-item-description">Navigate modern business complexities with our strategic insights. Whether scaling, pivoting, or starting, our innovative approaches ensure your strategy is visionary and actionable.</p>
              </div>
            </div>
          </div>


        </div>

        <div className="snippets">
          <div className="snippets-header">
            <h1 className="snippets-header-title">Data, Analytics &amp; Intelligence</h1>
            <p className="snippets-header-description">We understand every organization is at a different stage in its data journey. Whether you're building a data-driven culture, optimizing processes, or advancing with AI, our team provides the expertise to guide and implement tailored solutions. From data strategies and governance to advanced analytics and AI automation, we turn data into a strategic asset that drives your mission forward.</p>
          </div>
          <div className="snippets-container">
            {
              snippetItems.map((item, i) => (
                <div className="snippet-item-container">
                  <img className="snippet-item-image" src={item.imageUrl} alt={item.title} />
                  <p className="snippet-item-title">{item.title}</p>
                  <p className="snippet-item-snippet">{item.snippet}</p>
                </div>
              ))
            }
          </div>
        </div>

        <div className="contact-us-button-container">
          <button className="contact-us-button" onClick={() => navigate("/contact")}>
            Contact Us
          </button>
        </div>

        <div className="quote-container" style={{ backgroundImage: "url(/lightbulb.png)" }}>
          <div className="quote-inner-container">
            <h1 className="quote-text">“Every successful organization has to make the transition from a world defined primarily by repetition to one primarily defined by change.” </h1>
            <p className="quote-author">Lisa Bodell</p>
          </div>
        </div>


      </div>
      <Footer />
    </>
  )
}

export { Services };
