import React, { useEffect } from "react";
import "./PrivacyPolicy.css";
import { Navbar, Footer } from "../../Components";

const items = [
  {
    title: "Introduction",
    description: ["Welcome to Tiger Byte Consulting (TBC). We are committed to protecting the privacy of our website visitors and users. This Privacy Policy outlines how we collect, use, and protect your personal information when you interact with our website."],
  },
  {
    title: "Data We Collect",
    description: [
      "Personal Data: We may collect personal data such as name, email address, phone number, etc., when voluntarily submitted by our visitors for the purpose of inquiries, service provision, or other interactions.",
      "Usage Data: We collect data related to your usage of our website, including pages visited, time spent, browser type, and other related metrics.",
      "Cookies: Our website uses cookies to improve user experience, track visits, and remember preferences."
    ],
  },
  {
    title: "How We Use Your Data",
    description: [
      "Service Provision: We use your data to provide the services you've requested, respond to inquiries, or tailor our offerings based on your preferences.",
      "Improvement: We utilize usage data to enhance our website's functionality, understand user behaviors, and improve overall user experience.",
      "Communication: With your permission, we may use your contact details to send newsletters, updates, or other relevant communications.",
    ],
  },
  {
    title: "Data Protection",
    description: ["We implement industry-standard security measures to ensure your personal data's protection from unauthorized access, alteration, or deletion."],
  },
  {
    title: "Sharing Your Data",
    description: ["We do not sell, trade, or rent user personal identification information to others. We may share aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners and trusted affiliates for the purposes outlined above."],
  },
  {
    title: "Your Rights",
    description: ["You have the right to:"],
  },
  {
    title: "Third-Party Websites",
    description: ["Our website may contain links to other websites. Once redirected to another website, this Policy is no longer applicable. We advise reading the privacy policies of all external websites you visit."],
  },

  {
    title: "Changes to This Policy",
    description: ["TBC reserves the right to update or modify this Privacy Policy at any time. Any changes will be immediately posted on our website, and we encourage users to check this page frequently"],
  },
  {
    title: "Contact Us",
    description: ["If you have any questions regarding our Privacy Policy or your data's handling, please reach out to us at:", "Email: info@tigerbyte.ai"],
  }
]

function PrivacyPolicy() {


  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
    console.log(window.location)
  }, [window.location.pathname]);

  return (
    <>
      <Navbar />
      <div className="privacy-policy">
        <h1>Privacy Policy</h1>
        <div className="privacy-content">
          {
            items.map((val, i) => {
              return (
                <div key={i} >
                  <h2 className="privacy-item-title">{`${i + 1}. ${val.title}`}</h2>
                  <p className="privacy-item-description">
                    {
                      val.description.map((val) => (
                        <>
                          <p>{val}</p>

                          {/* <br /> */}
                        </>
                      ))
                    }
                  </p>
                </div>
              )
            })
          }
        </div>
      </div>
      <Footer />
    </>
  )
}

export { PrivacyPolicy };