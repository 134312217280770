import React from "react";
import { Footer, Navbar } from "../../Components";
import "./404.css";
import { useNavigate } from "react-router-dom";

export function NotFound() {

    const navigate = useNavigate();

    return (
        <>
            <Navbar />
            <div className="not-found">
                <div className="error-not-found">
                    <h1 className="error-title">404</h1>
                    <h3 className="error-description">Oops! This page was not found, it either does not exist, or has been moved or deleted.</h3>
                </div>
                <button className="error-button" onClick={() => navigate("/")}>
                    Go Home
                </button>
            </div>
            <Footer />
        </>
    )
}